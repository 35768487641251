;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"da8b03e0e4adfd5a733e2904ce02f67802d89ad6"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/backoffice/v0.3.13-da8b03e0";import * as Sentry from '@sentry/nextjs';

const DSN_STAGING =
  'https://eed3a9669bcb49cfa2abdc017b853ca4@o216259.ingest.us.sentry.io/2290372';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_URL || DSN_STAGING;

const isStaging = SENTRY_DSN === DSN_STAGING;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: isStaging ? 'staging' : 'production',
  ignoreErrors: [
    'gtag is not defined',
    'gtag',
    'Cancel rendering route',
    'The operation is insecure.',
    /ResizeObserver/,
    'Fetch is aborted',
    'Non-Error promise rejection captured with value: Ignore this error'
  ],
  tracesSampleRate: isStaging ? 0 : 0.1
});
