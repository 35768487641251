import { BuyerOffer, ListingRequest } from '@/components/buyer/interfaces';
import {
  MeetingProps,
  OnlineAlert,
  OnlineValuation,
  OpportunityQualification
} from '@/components/qualification/sellerQualification/interfaces';
import { Visit } from '@/components/visit/interfaces';
import { Country, Language, nullOrString, UserProps } from '@proprioo/hokkaido';

import { ExistingContract } from '../listing/contract/Contract.interfaces';
import { ResearchMandate } from '../researchMandate/researchMandate.interfaces';

export type AddressInformation = {
  city?: string;
  postCode?: string;
  street?: string;
  streetNumber?: string;
};

export type FullAddressInformation = AddressInformation & {
  longitude?: number;
  latitude?: number;
};

export type Customer = UserProps &
  FullAddressInformation & {
    promotionalConsent: boolean;
  };

export type CustomerInformation = Customer & {
  about: nullOrString;
  id: string;
  isAgent: boolean;
  language: Language;
  modifiedBy: nullOrString;
};

export type UserInformations = {
  alerts: OnlineAlert[];
  contracts: ExistingContract[];
  listingRequests: ListingRequest[];
  meetings: MeetingProps[];
  offers: BuyerOffer[];
  opportunities: OpportunityQualification[];
  researchMandates: ResearchMandate[];
  user: CustomerInformation;
  valuations: OnlineValuation[];
  visits: Visit[];
};

export type FetchCustomer = {
  countOpportunities: number;
  countAlerts: number;
  user: CustomerInformation;
};

export type Agent = {
  activationDate: nullOrString;
  chatId: nullOrString;
  country: Country;
  email: string;
  firstname: string;
  id: number;
  isBlocked: boolean;
  isDuplicated: boolean;
  lastname: string;
  latitude: number;
  longitude: number;
  phone: string;
  picture: nullOrString;
  squads: SquadAgent[];
  userRoles: UserRole[];
};

export type agentOrUndefined = Agent | undefined;

export enum UserRole {
  APP_SALES_ADMIN = 'AppSalesAdmin',
  IREC = 'Irec',
  MOPS = 'Mops',
  IS = 'InsideSales',
  PREMIUM_PARTNER = 'PremiumPartner',
  SALES = 'Sales',
  SELLER_SUCCESS = 'SellerSuccess',
  TEAM_LEADS = 'TeamLeads',
  TECH = 'Tech'
}

type BaseUserProps = {
  email: string;
  roles: UserRole[];
};

export type AuthUserProps = BaseUserProps & {
  lng: Language;
  // User may be impersonated.
  // When it's the case, original user information is stored here.
  original?: BaseUserProps;
};

export type AuthProps = {
  isConnected: boolean;
  user?: AuthUserProps & {
    permissions: FormattedUserPermissions;
  };
};

export type StatusEndpointProps = {
  isAnonymous: boolean;
  user?: AuthUserProps;
  userPermissions?: FormattedUserPermissions;
};

export enum SquadRole {
  AGENT = 'Agent',
  LEAD = 'Lead'
}

type SquadBase = {
  id: string;
  name: string;
  color: string;
};

export type Squad = SquadBase & {
  lead: agentOrUndefined;
  agents: Agent[];
};

export type Squads = Record<string, Squad>;

export type PartialSquad = Pick<Squad, 'id' | 'name'>;

export type SquadAgent = SquadBase & {
  role: SquadRole;
  orderedSubstitutes: number[];
};

export type SquadOrUndefined = Squad | undefined;

export type SquadAgentOrNull = SquadAgent | null;

export type AgentPictureAvatar = Record<number, nullOrString>;

export enum FeatureType {
  EXTERNAL_LINK = 'ExternalLink',
  FEATURE = 'Feature',
  PAGE = 'Page',
  SECTION = 'Section',
  TAB = 'Tab'
}

export enum Permission {
  CREATE = 'Create',
  READ = 'Read',
  UPDATE = 'Update'
}

export type Feature = {
  createdAt: string;
  modifiedAt: nullOrString;
  modifiedBy: nullOrString;
  right: Permission;
  role: UserRole;
  type: FeatureType;
  url: string;
};

export type UserPermissions = {
  features: Feature[];
  roles: UserRole[];
  userEmail: string;
};

type PermissionsDictionary = Record<string, Permission[]>;

export type FormattedUserPermissions = {
  features: PermissionsDictionary;
  sections: PermissionsDictionary;
  subsections: PermissionsDictionary;
  urls: PermissionsDictionary;
};
