import { ReactElement } from 'react';

import {
  AlertObjectPayload,
  LocationState,
  ProjectPayload,
  UserPayload
} from '@/components/buyerQualification/interfaces';
import { ListingProps } from '@/components/listing/interfaces';
import { Invoice } from '@/components/sale/interfaces';
import { CustomerInformation } from '@/components/state/interfaces';
import {
  Country,
  DocumentProps,
  EstimationStage,
  LeadSource,
  nullOrBoolean,
  nullOrNumber,
  nullOrString,
  OpportunityProps,
  OptionProps,
  PriorityEnum,
  PropertyEnum,
  PropertyProps,
  ValuationTypes
} from '@proprioo/hokkaido';

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type Projet = {
  agent: OptionProps | null;
  creationOrigin: OpportunityOrigin;
  declaredAsBuyer: nullOrBoolean;
  hasLeadProvider: boolean;
  leadComments: nullOrString;
  leadProvider: nullOrNumber;
  leadSource: OptionProps | null;
  reallocateActivities: boolean;
  transactionId: nullOrString;
  treatmentPriority: OptionProps | null;
  updateViewingAgent: boolean;
  pigeId?: string;
  reasonForSelling?: string;
  askingPrice?: number;
  ownershipOption: OptionProps | null;
};

export type ExtendedProject = Overwrite<
  Omit<PropertyProps, 'period' | 'numberOfRooms'>,
  Projet
> & {
  country?: Country;
};

export enum OpportunityOrigin {
  BACKOFFICE = 'Backoffice',
  BOOKING = 'Estimation',
  SALES = 'Sales',
  PLANNER = 'Planner',
  VALUATION = 'OnlineValuation'
}

export enum AppointmentStatus {
  ACTIVATED = 'ACTIVATED',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
  PENDING = 'PENDING'
}

export enum AppointmentSubStatus {
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
  MODIFIED = 'MODIFIED',
  NOSHOW = 'NOSHOW',
  TOBEDONE = 'TOBEDONE'
}

export type Appointment = {
  dateTime: string;
  userId: string;
  referenceId: string;
  subtype: ValuationTypes;
};

export type AppointmentProps = {
  agentId: number;
  appointment: Appointment;
  id: string;
};

export type ValuationMeeting = {
  appointmentId: string;
  createdAt: string;
  estimationId: number;
  id: string;
  status: AppointmentStatus;
  substatus: AppointmentSubStatus;
};

export type MeetingProps = ValuationMeeting & {
  agentId: number;
  isActive: boolean;
  dateTime: string;
  subtype: ValuationTypes;
};

export type Transaction = {
  casavoId: string;
  createdAt: string;
  estimationId: string;
};

export const sellerOwnership = {
  none: 'none',
  oneOfTheOwners: 'one_of_the_owners',
  relativeOfTheOwner: 'relative_of_the_owner',
  uniqueOwner: 'unique_owner'
} as const;
export type SellerOwnership =
  (typeof sellerOwnership)[keyof typeof sellerOwnership];

export const treatmentPriority = {
  LOWEST: 'lowest',
  NOT_INTERESTED_SELLING: 'notInterestedSelling',
  ...PriorityEnum
} as const;
/**
 * TreatmentPriority represents the urgency level of a seller's project.
 * This type is a specialized version of PriorityEnum from hokkaido package,
 * created to handle specifically the treatment priority use case and decouple
 * it from the more generic priority enum.
 * Evaluate whether this type should be moved to the hokkaido library
 */
export type TreatmentPriority =
  (typeof treatmentPriority)[keyof typeof treatmentPriority];

export type OpportunityQualification = OpportunityProps & {
  appointmentId: nullOrString;
  archiveComment: nullOrString;
  archiveStages: EstimationStage[];
  askingPrice?: number;
  ownershipSource?: SellerOwnership;
  reasonForSelling?: string;
  id: number;
  country: Country;
  createdAt: string;
  creationOrigin: OpportunityOrigin;
  declaredAsBuyer: boolean;
  estimationStage: EstimationStage;
  invoices: Invoice[];
  lastStage: EstimationStage;
  leadComments: string;
  leadProvider: nullOrNumber;
  leadSource: LeadSource;
  listing: ListingProps | null;
  locationFromIris: LocationState;
  locationFromNeighborhood: LocationState;
  locationFromMunicipality: LocationState;
  locationFromSubmunicipality: LocationState;
  modified: string;
  postCode: string;
  salesforceId: string;
  transactions: Transaction[] | null;
  documents?: DocumentProps[];
  pigeId?: string;
  price?: number;
  transactionId?: string;
  valuationMeetings?: ValuationMeeting[];
  visibleByAgent?: string;
  treatmentPriority: TreatmentPriority; //TODO - remove when TreatmentPriority is moved in hokkaido lib
};

export type Options<T> = {
  image: ReactElement;
  text: string;
  value: T;
};

export type OnlineValuation = {
  address: string;
  buildingYear: number;
  createdAt: string;
  livingArea: number;
  numberOfRooms: number;
  priority: PriorityEnum;
  propertyType: PropertyEnum;
  valuationLower: number;
  valuationUpper: number;
  valuationWithMarginLower: number;
  valuationWithMarginUpper: number;
  landArea?: number;
};

export type OnlineAlert = {
  alert: AlertObjectPayload;
  alertID: string;
  createdAt: string;
  lastModified: string;
  project: ProjectPayload;
  projectID: string;
  salesforceID?: string;
  user: UserPayload;
  userID: string;
  score?: number;
};

export type Project = ExtendedProject & {
  agent: OptionProps;
  country?: Country;
  leadSource: { label: string; value: LeadSource };
  livingArea: number;
  treatmentPriority: { label: string; value: PriorityEnum };
};

export type CreateQualificationBody = {
  customer: CustomerInformation;
  project: Project;
};

export type FinalUser = CustomerInformation & {
  id: string;
};

export type OpportunityResponse = {
  id: string;
};
